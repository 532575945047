<mat-form-field class="select-menu" appearance="fill" [subscriptSizing]="'dynamic'">
  <mat-label>{{ placeholderText }}</mat-label>
  <mat-select
    [formControl]="selectField"
    [placeholder]="placeholderText"
    [(value)]="value"
    [multiple]="multiple"
  >
    <mat-select-trigger *ngIf="multiple">
      {{ selectField.value ? selectField.value[0] : '' }}
      @if (selectField.value?.length > 1) {
      <span> (+{{ selectField.value.length - 1 }} <ng-container i18n>more</ng-container>) </span>
      }
    </mat-select-trigger>
    @for (option of options; track option.id) {
    <mat-option [value]="option[valueProp]">
      {{ option[viewValueProp] }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
