<nav class="header__links" [ngSwitch]="location" fxLayout="row" fxLayoutAlign="start center">
  <span class="mat-body-2">
    <ng-container *ngSwitchCase="'teacher'" i18n>Welcome {{ user.firstName }}!</ng-container>
    <ng-container *ngSwitchCase="'student'" i18n>Welcome {{ user.firstName }}!</ng-container>
  </span>
  <ng-container *ngSwitchDefault>
    <a
      mat-button
      routerLink="/features"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      i18n
      >Features</a
    >
    <a
      mat-button
      routerLink="/about"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      i18n
      >About Us</a
    >
    <a mat-button href="https://wise-discuss.berkeley.edu" i18n>Community</a>
    <a mat-button href="https://wise-research.berkeley.edu" i18n>Research</a>
  </ng-container>
  <app-header-signin *ngIf="roles.length === 0"></app-header-signin>
</nav>
