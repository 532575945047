<h2 mat-dialog-title i18n>Share Unit</h2>
<mat-dialog-content class="dialog-content-scroll">
  <p class="mat-body-1 accent-1">
    {{ project.name }} <span class="mat-caption" i18n>(Unit ID: {{ project.id }})</span>
  </p>
  <p>
    <strong i18n
      >Sharing a curriculum unit allows other WISE teachers to use it with their own students and
      optionally edit the unit's content.</strong
    >
  </p>
  <p i18n>Shared units appear in a teacher's "My Units" library.</p>
  <mat-divider></mat-divider>
  <mat-form-field appearance="fill">
    <mat-label i18n>Find a teacher</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input
      id="share-project-dialog-search"
      type="text"
      matInput
      [formControl]="teacherSearchControl"
      [matAutocomplete]="auto"
    />
    <button
      matSuffix
      mat-icon-button
      [ngClass]="{ invisible: !teacherSearchControl.value }"
      i18n-aria-label
      aria-label="Clear"
      (click)="teacherSearchControl.setValue('')"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint
      *ngIf="duplicate && allTeacherUsernames.includes(teacherSearchControl.value)"
      class="info"
      i18n
      >Teacher is already on shared list.</mat-hint
    >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="shareProject()">
      <mat-option
        *ngFor="let teacherUsername of filteredTeacherUsernames | async"
        [value]="teacherUsername"
      >
        {{ teacherUsername }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef i18n>Name</th>
      <td mat-cell *matCellDef="let sharedOwner">
        <ng-container *ngIf="sharedOwner.isOwner">
          {{ project.owner.firstName }} {{ project.owner.lastName }}
        </ng-container>
        <ng-container *ngIf="!sharedOwner.isOwner">
          {{ sharedOwner.firstName }} {{ sharedOwner.lastName }} (<a
            [routerLink]=""
            (click)="unshareProject(sharedOwner)"
            class="warn"
            i18n
            >Revoke</a
          >)
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="permissions">
      <th mat-header-cell *matHeaderCellDef i18n>Permissions</th>
      <td mat-cell *matCellDef="let sharedOwner">
        <ng-container *ngIf="sharedOwner.isOwner" i18n>Owner. Full permissions.</ng-container>
        <ng-container *ngIf="!sharedOwner.isOwner">
          <div fxLayout="row wrap" fxLayoutGap="12px">
            <mat-checkbox [(ngModel)]="sharedOwner.projectPermissions[1]" disabled="true">
              <ng-container i18n>View & Use with Students</ng-container>
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="sharedOwner.projectPermissions[2]"
              (change)="
                projectPermissionChanged(
                  project,
                  sharedOwner.id,
                  2,
                  sharedOwner.projectPermissions[2]
                )
              "
            >
              <ng-container i18n>Edit Unit Content</ng-container>
            </mat-checkbox>
          </div>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close i18n>Done</button>
</mat-dialog-actions>
