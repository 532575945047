<div class="main main--fullwidth home">
  <app-hero-section
    [imgSrc]="hero.imgSrc"
    [imgSources]="hero.imgSources"
    [imgDescription]="hero.imgDescription"
  >
    <ng-template #headlineTemplate>
      <div class="accent-2" fxLayoutGap="8px" fxLayoutGap.gt-xs="16px">
        <mat-icon
          [@jackInTheBox]="{ value: '*', params: { delay: '1s' } }"
          svgIcon="ki-elicit"
          i18n-aria-label
          aria-label="Elicit Ideas"
        ></mat-icon>
        <mat-icon
          [@bounceIn]="{ value: '*', params: { delay: '1250ms' } }"
          svgIcon="ki-add"
          i18n-aria-label
          aria-label="Add Ideas"
        ></mat-icon>
        <mat-icon
          [@rotateIn]="{ value: '*', params: { delay: '1500ms' } }"
          svgIcon="ki-distinguish"
          i18n-aria-label
          aria-label="Distinguish Ideas"
        ></mat-icon>
        <mat-icon
          [@flipInY]="{ value: '*', params: { delay: '2s' } }"
          svgIcon="ki-connect"
          i18n-aria-label
          aria-label="Connect Ideas and Reflect"
        ></mat-icon>
      </div>
    </ng-template>
    <ng-template #taglineTemplate>
      <div [@flipInX]="{ value: '*', params: { delay: '2750ms' } }" i18n>
        Integrated science learning and teaching with technology
      </div>
    </ng-template>
    <ng-template #sideTemplate *ngIf="isDiscourseNewsAvailable">
      <discourse-latest-news
        [@bounceIn]="{ value: '*', params: { duration: '1.25s', delay: '3500ms' } }"
        [baseUrl]="discourseUrl"
        [category]="discourseNewsCategory"
        queryString="order=latest"
      />
    </ng-template>
  </app-hero-section>
  <section class="section">
    <div class="section--fullwidth section--slant mat-app-background">
      <div class="content">
        <h1
          class="accent section__header"
          ngClass="mat-headline-4"
          [ngClass.xs]="{ 'mat-headline-4': false }"
          i18n
        >
          The WISE Advantage
        </h1>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
          fxLayoutAlign.xs="center center"
          fxLayoutGap="32px"
          fxLayoutGap.sm="24px"
          fxLayoutGap.gt-sm="32px"
        >
          <app-blurb
            *ngFor="let blurb of blurbs"
            fxFlex
            fxFlex.gt-xs="33"
            [imgSrc]="blurb.imgSrc"
            [imgDescription]="blurb.imgDescription"
            [imgSources]="blurb.imgSources"
            [content]="blurb.content"
            [headline]="blurb.headline"
          >
            <ng-template *ngIf="blurb.contentTemplate" #contentTemplate>
              <span [innerHtml]="blurb.contentTemplate"></span>
            </ng-template>
            <ng-template *ngIf="blurb.headlineTemplate" #headlineTemplate>
              <span [innerHtml]="blurb.headlineTemplate"></span>
            </ng-template>
          </app-blurb>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="section--fullwidth section--slant section--slant--rtl app-background">
      <div class="content">
        <h1
          class="accent section__header"
          ngClass="mat-headline-4"
          [ngClass.xs]="{ 'mat-headline-4': false }"
          i18n
        >
          Curriculum Offerings
        </h1>
        <app-home-page-project-library></app-home-page-project-library>
        <div class="library__links">
          <h2
            class="accent-1 center"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="16px"
            fxLayoutAlign="center center"
            fxLayoutWrap
          >
            <span i18n>Ready to try WISE in the Classroom?</span>
            <a mat-raised-button color="accent" routerLink="/join" i18n>Sign up for free!</a>
          </h2>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="section--fullwidth section--slant mat-app-background">
      <div class="content">
        <h1
          class="accent section__header"
          ngClass="mat-headline-4"
          [ngClass.xs]="{ 'mat-headline-4': false }"
          fxLayoutAlign="start center"
          fxLayoutGap="24px"
        >
          <span i18n>Connect</span>
          <a
            class="social-link"
            href="https://www.facebook.com/wisetels/"
            target="_blank"
            i18n-aria-label
            aria-label="WISE on Facebook"
          >
            <mat-icon svgIcon="facebook"></mat-icon>
          </a>
          <a
            class="social-link"
            href="https://twitter.com/WISETELS"
            target="_blank"
            i18n-aria-label
            aria-label="WISE on Twitter"
          >
            <mat-icon svgIcon="twitter"></mat-icon>
          </a>
          <a
            class="social-link"
            href="https://github.com/WISE-Community"
            target="_blank"
            i18n-aria-label
            aria-label="WISE on Github"
          >
            <mat-icon svgIcon="github"></mat-icon>
          </a>
        </h1>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
          fxLayoutAlign.xs="center center"
          fxLayoutGap="32px"
          fxLayoutGap.sm="24px"
          fxLayoutGap.gt-sm="32px"
        >
          <app-call-to-action
            fxFlex
            fxFlex.gt-xs="33"
            icon="forum"
            iconColor="accent-1"
            i18n-headline
            headline="Community"
            i18n-content
            content="Join us! Discuss all things WISE."
            destination="https://wise-discuss.berkeley.edu"
            linkTarget="_self"
            [isOutsideLink]="true"
          >
          </app-call-to-action>
          <app-call-to-action
            fxFlex
            fxFlex.gt-xs="33"
            icon="live_help"
            iconColor="accent-2"
            i18n-headline
            headline="Help + FAQs"
            i18n-content
            content="View tutorials and common questions."
            destination="/help"
          >
          </app-call-to-action>
          <app-call-to-action
            fxFlex
            fxFlex.gt-xs="33"
            icon="contact_mail"
            iconColor="accent"
            i18n-headline
            headline="Contact Us"
            i18n-content
            content="Want to get in touch? Send us a message."
            destination="/contact"
          ></app-call-to-action>
        </div>
      </div>
    </div>
  </section>
</div>
