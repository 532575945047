<button mat-icon-button [matMenuTriggerFor]="projectMenu">
  <mat-icon aria-label="Project Menu">more_vert</mat-icon>
</button>
<mat-menu #projectMenu="matMenu" xPosition="before">
  <div class="header-account-menu">
    <ng-container *ngIf="isChild">
      <div class="menu-info secondary-text" i18n>Parent ID: {{ project.parentId }}</div>
      <mat-divider></mat-divider>
    </ng-container>
    <a mat-menu-item (click)="copyProject()">
      <mat-icon>content_copy</mat-icon> <ng-container i18n>Copy</ng-container>
    </a>
    <a mat-menu-item (click)="editProject()" *ngIf="isCanEdit">
      <mat-icon>edit</mat-icon> <ng-container i18n>Edit</ng-container>
    </a>
    <a mat-menu-item (click)="shareProject()" *ngIf="isCanShare">
      <mat-icon>share</mat-icon> <ng-container i18n>Share</ng-container>
    </a>
    <a mat-menu-item *ngIf="!archived" (click)="archive(true)">
      <mat-icon>archive</mat-icon>
      <span i18n>Archive</span>
    </a>
    <a mat-menu-item *ngIf="archived" (click)="archive(false)">
      <mat-icon>unarchive</mat-icon>
      <span i18n>Restore</span>
    </a>
  </div>
</mat-menu>
