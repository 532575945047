<h2 mat-dialog-title i18n>WISE Tested</h2>
<div mat-dialog-content>
  <div class="info-block">
    <p i18n>The "WISE Tested" library contains curricula developed by the WISE team.</p>
    <p i18n>
      Each of the units in this collection has been designed and iteratively refined through a
      partnership of university-based education researchers and science teachers. They have all been
      implemeneted and tested in real classrooms and have shown proven learning gains.
    </p>
    <p i18n>
      <a routerLink="/about" target="_blank">Learn more</a> about our design process and
      <a href="https://wise-research.berkeley.edu" target="_blank"
        >check out our research and publications</a
      >.
    </p>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()" i18n>Close</button>
</div>
