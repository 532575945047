<h2 mat-dialog-title class="mat-dialog-title--icons" fxLayoutAlign="start center">
  <span i18n>Edit Classroom Unit</span>
  <span fxFlex></span>
  <mat-icon color="warn" i18n-aria-label aria-label="Warning">warning</mat-icon>
</h2>
<mat-dialog-content>
  <div class="info-block">
    <p class="mat-subtitle-1 accent-1">
      {{ run.project.metadata.title }}
      <span *ngIf="run" class="mat-caption" i18n>(Run ID: {{ run.id }})</span>
    </p>
    <p i18n>
      Warning! You will be editing the content of a classroom unit. If students have already started
      working, this may result in lost data or other problems.
    </p>
    <p i18n>Are you sure you wish to proceed?</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close i18n>Cancel</button>
  <a mat-button (click)="editContent()" color="warn" i18n>Proceed</a>
</mat-dialog-actions>
