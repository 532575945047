<h2 mat-dialog-title class="mat-dialog-title">{{ data.title }}</h2>
<mat-dialog-content>
  <div class="info-block" [innerHTML]="data.content"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  @for (button of data.buttons; track button) {
    <a mat-button color="{{ button.color }}" href="{{ button.link }}">
      {{ button.text }}
    </a>
  }
  <button mat-button mat-dialog-close cdkFocusRegionstart i18n>Close</button>
</mat-dialog-actions>
