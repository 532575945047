<button mat-icon-button [matMenuTriggerFor]="accountMenu" i18n-aria-label aria-label="Account Menu">
  <mat-icon *ngIf="roles.includes('student'); else teacher">account_circle</mat-icon>
  <ng-template #teacher>
    <mat-icon>account_box</mat-icon>
  </ng-template>
</button>
<mat-menu #accountMenu="matMenu" xPosition="before">
  <div class="header-account-menu">
    <div class="user-info" fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="8px">
      <mat-icon
        *ngIf="roles.includes('student'); else teacherAvatar"
        i18n-aria-label
        aria-label="Account avatar"
        >account_circle</mat-icon
      >
      <ng-template #teacherAvatar>
        <mat-icon i18n-aria-label aria-label="Account avatar">account_box</mat-icon>
      </ng-template>
      <span>{{ firstName }} {{ lastName }}</span>
    </div>
    <div *ngIf="isPreviousAdmin" class="user-switch">
      <a (click)="switchToOriginalUser()" i18n>Switch back to original user</a>
    </div>
    <mat-divider></mat-divider>
    <a
      *ngIf="roles.includes('student')"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      mat-menu-item
      routerLink="/student"
    >
      <mat-icon>home</mat-icon>
      <span i18n>Student Home</span>
    </a>
    <a
      *ngIf="roles.includes('teacher')"
      mat-menu-item
      routerLink="/teacher"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon>home</mat-icon>
      <span i18n>Teacher Home</span>
    </a>
    <a
      *ngIf="roles.includes('student')"
      mat-menu-item
      routerLink="/student/profile/edit"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon>edit</mat-icon>
      <span i18n>Edit Profile</span>
    </a>
    <a
      *ngIf="roles.includes('teacher')"
      mat-menu-item
      routerLink="/teacher/profile/edit"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon>edit</mat-icon>
      <span i18n>Edit Profile</span>
    </a>
    <a *ngIf="roles.includes('researcher')" mat-menu-item (click)="switchToAdmin()">
      <mat-icon>settings</mat-icon>
      <span i18n>Researcher Tools</span>
    </a>
    <a *ngIf="roles.includes('admin')" mat-menu-item (click)="switchToAdmin()">
      <mat-icon>settings</mat-icon>
      <span i18n>Admin Tools</span>
    </a>
    <a
      mat-menu-item
      routerLink="/help"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon>help</mat-icon>
      <span i18n>Help</span>
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="logOut()">
      <mat-icon color="warn">exit_to_app</mat-icon>
      <span class="warn" i18n>Sign Out</span>
    </a>
  </div>
</mat-menu>
