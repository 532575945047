<div class="blurb mat-elevation-z3">
  <picture *ngIf="imgSrc">
    <ng-container *ngFor="let source of imgSources">
      <source
        media="{{ source.media }}"
        sizes="{{ source.sizes }}"
        src="{{ source.src }}"
        srcset="{{ source.srcset }}"
        type="{{ source.type }}"
      />
    </ng-container>
    <img class="blurb__image" src="{{ imgSrc }}" alt="{{ imgDescription }}" />
  </picture>
  <hr class="blurb__divider accent-2-bg" />
  <div class="blurb__content">
    <ng-template #defaultHeadline>{{ headline }}</ng-template>
    <h2 *ngIf="headline || headlineRef" class="accent">
      <ng-container *ngTemplateOutlet="headlineRef ? headlineRef : defaultHeadline"></ng-container>
    </h2>
    <ng-template #defaultContent>{{ content }}</ng-template>
    <p *ngIf="content || contentRef">
      <ng-container *ngTemplateOutlet="contentRef ? contentRef : defaultContent"></ng-container>
    </p>
  </div>
</div>
