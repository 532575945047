<div class="library library-home notice-bg-bg">
  <div fxLayout="column" fxLayout.gt-sm="row">
    <div class="content-block dark-theme library__header primary-bg" fxFlex.gt-sm="33">
      <app-library-filters [isSplitScreen]="true"/>
    </div>
    <div class="library__content library-home__content content-block" fxFlex.gt-sm="67">
      <div class="notice center" i18n>
        Explore suggested WISE curricula for the given grade levels or search for specific units
        that address your needs.
      </div>
      <app-official-library [isSplitScreen]="true"/>
    </div>
  </div>
</div>
