<h2 mat-dialog-title i18n>Use with Class</h2>
<form *ngIf="!isCreated" [formGroup]="form" (ngSubmit)="create()">
  <mat-dialog-content class="dialog-content-scroll">
    <p class="mat-subtitle-1 accent-1">
      {{ project.metadata.title }} <span class="mat-caption" i18n>(Unit ID: {{ project.id }})</span>
    </p>
    <h3 i18n>1. Choose Periods</h3>
    <p formArrayName="selectedPeriods">
      <span *ngFor="let control of selectedPeriodsControl.controls" [formGroup]="control">
        <mat-checkbox formControlName="checkbox" class="period-select">
          {{ control.controls.name.value }}
        </mat-checkbox>
      </span>
    </p>
    <mat-form-field appearance="fill" class="period-input form-field-long-hint">
      <mat-label i18n>Add your own periods</mat-label>
      <input matInput id="customPeriods" name="customPeriods" formControlName="customPeriods" />
      <mat-hint i18n
        >For "Period 9", just enter the number 9. Separate periods with commas (e.g. "Section 1,
        Section 2"). Manually named periods should be no more than 16 characters long.
      </mat-hint>
    </mat-form-field>
    <mat-divider></mat-divider>
    <h3 i18n>2. Choose Students Per Team</h3>
    <mat-radio-group #rGroup formControlName="maxStudentsPerTeam" fxLayoutGap="16px">
      <mat-radio-button value="1" radioGroup="rGroup" i18n>Only 1 student</mat-radio-button>
      <mat-radio-button value="3" radioGroup="rGroup" i18n>1-3 students</mat-radio-button>
    </mat-radio-group>
    <mat-divider></mat-divider>
    <h3 i18n>3. Set Schedule</h3>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.gt-xs="16px">
      <div>
        <mat-form-field
          appearance="fill"
          fxFlexAlign="start"
          fxFlex="0 0 auto"
          fxFlex.xs="0 0 100%"
        >
          <mat-label i18n>Start date</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
            (dateChange)="setDateRange()"
            [max]="maxStartDate"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error i18n>Start date is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          appearance="fill"
          fxFlexAlign="start"
          fxFlex="0 0 auto"
          fxFlex.xs="0 0 100%"
        >
          <mat-label i18n>End date</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
            (dateChange)="setDateRange()"
            [min]="minEndDate"
          />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxLayoutAlign="start center" style="padding-bottom: 1.34375em">
        <mat-checkbox formControlName="isLockedAfterEndDate" i18n>
          Lock After End Date </mat-checkbox
        >&nbsp;
        <mat-icon
          class="info"
          tabindex="0"
          matTooltip="If the End Date has passed and the unit is locked, students will no longer be able to save new work"
          matTooltipPosition="above"
          i18n-matTooltip
          >help</mat-icon
        >
      </div>
    </div>
    <p class="mat-caption" i18n>
      Note: These dates can be changed at any time from your Class Schedule. Just select "Edit
      Settings" from the unit's dropdown menu.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="8px">
    <button mat-button mat-dialog-close [disabled]="isCreating" i18n>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="form.invalid || isCreating"
      class="button--progress"
    >
      <mat-progress-bar *ngIf="isCreating" mode="indeterminate"></mat-progress-bar>
      <ng-container i18n>Create Run</ng-container>
    </button>
  </mat-dialog-actions>
</form>
<ng-container *ngIf="isCreated">
  <mat-dialog-content>
    <div class="info-block">
      <p class="mat-subtitle-2" i18n>Success! This unit has been added to your Class Schedule.</p>
      <p class="mat-subtitle-1 accent-1">{{ run.name }}</p>
      <p class="mat-subtitle-1 accent" i18n>Access Code: {{ run.runCode }}</p>
      <p i18n>
        Important: Every classroom unit has a unique Access Code. Students use this code to register
        for a unit. Give the code to your students when they first sign up for a WISE account. If
        they already have WISE accounts, have them log in and then select "Add Unit" from the
        student home page.
      </p>
      <p i18n>
        You can always find the Access Code for each classroom unit in your Class Schedule.
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="checkClassroomAuthorization()"
      *ngIf="isGoogleUser() && isGoogleClassroomEnabled()"
      i18n
    >
      Share to Google Classroom
    </button>
    <button mat-button (click)="closeAll()" i18n>Done</button>
  </mat-dialog-actions>
</ng-container>
