<div class="library-project-details" mat-dialog-content>
  <div fxLayout="row">
    <div fxLayout="row">
      <div class="mat-mdc-card-avatar" [style.background-image]="project.thumbStyle"></div>
      <div fxFlex="1 1 100%" fxLayout="row" fxLayoutWrap>
        <div fxLayout="column">
          <div class="mat-mdc-card-title mat-body-1">{{ project.metadata.title }}</div>
          <div class="mat-mdc-card-subtitle mat-caption">
            <div *ngIf="project.metadata.grades.length || project.metadata.totalTime">
              <ng-container *ngIf="project.metadata.grades.length" i18n>Grade level:</ng-container>
              <span *ngFor="let g of project.metadata.grades; let isLast = last">
                {{ g }}{{ isLast ? '' : ', ' }}</span
              >
              <ng-container *ngIf="project.metadata.grades.length && project.metadata.totalTime"
                >&nbsp;|&nbsp;</ng-container
              >
              <ng-container *ngIf="project.metadata.totalTime" i18n
                >Duration: {{ project.metadata.totalTime }}</ng-container
              >
            </div>
            <div>
              <ng-container i18n>Unit ID: {{ project.id }}</ng-container>
            </div>
          </div>
        </div>
        <span fxFlex="1 1 auto" fxHide fxShow.gt-xs></span>
      </div>
    </div>
    <span fxFlex></span>
    <app-library-project-menu
      *ngIf="isTeacher && project.wiseVersion !== 4"
      [project]="project"
      [isRun]="isRunProject"
    />
  </div>
  <div class="info-block">
    <div class="details">
      <p *ngIf="project.wiseVersion === 4" class="warn" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon color="warn">warning</mat-icon>&nbsp;
        <strong
          class="more-info"
          tabindex="0"
          i18n-matTooltip
          matTooltip="This unit is from an old version of WISE that is no longer supported. Please find an alternate unit to use in the future or contact us for upgrade options."
          [matTooltipPosition]="'after'"
          i18n
          >Legacy Unit</strong
        >
      </p>
      <div *ngIf="project.metadata.summary" [innerHTML]="project.metadata.summary"></div>
      <p *ngIf="ngss.disciplines.length">
        <strong i18n>Discipline:</strong>
        <ng-container *ngFor="let discipline of ngss.disciplines; let isLast = last">
          {{ discipline.name }}{{ isLast ? '' : ' / ' }}
        </ng-container>
      </p>
      <p *ngIf="project.metadata.features">
        <strong i18n>Features:</strong> {{ project.metadata.features }}
      </p>
      <p *ngIf="ngss.dciArrangements.length">
        <strong i18n>NGSS Performance Expectations: </strong>
        <ng-container *ngFor="let dcia of ngss.dciArrangements; let isLast = last">
          <a href="{{ ngssWebUrl }}{{ dcia.id }}" target="_blank">{{ dcia.id }} {{ dcia.name }}</a>
          <ng-container *ngIf="dcia.children.length">
            (<ng-container *ngFor="let pe of dcia.children; let isLastChild = last"
              ><a
                class="pe"
                href="{{ ngssWebUrl }}{{ pe.id }}"
                target="_blank"
                matTooltip="{{ pe.name }}"
                [matTooltipPosition]="'above'"
                >{{ pe.id }}</a
              >{{ isLastChild ? '' : ', ' }}</ng-container
            >) </ng-container
          >{{ isLast ? '' : ' / ' }}
        </ng-container>
      </p>
      <unit-tags [tags]="project.tags" />
    </div>
    <discourse-category-activity
      *ngIf="project.metadata.discourseCategoryURL"
      [categoryURL]="project.metadata.discourseCategoryURL"
    />
    <div class="license notice-bg-bg mat-caption secondary-text">
      <img src="../../../../assets/img/by-sa.png" alt="CC BY-SA" />&nbsp;
      <span *ngIf="!isCopy" [ngSwitch]="authorsString.length">
        <ng-container *ngSwitchCase="0" i18n
          ><a href="{{ project.uri }}" target="_blank">This unit</a> is licensed under
          <a href="{{ licenseUrl }}" target="_blank">CC BY-SA</a>.</ng-container
        >
        <ng-container *ngSwitchDefault i18n
          ><a href="{{ project.uri }}" target="_blank">This unit</a> is licensed under
          <a href="{{ licenseUrl }}" target="_blank">CC BY-SA</a> by
          {{ authorsString }}.</ng-container
        >
      </span>
      <span *ngIf="isCopy" [ngSwitch]="parentAuthorsString.length">
        <ng-container *ngSwitchCase="0" i18n
          ><a href="{{ project.uri }}" target="_blank">This unit</a> is a copy of
          <a href="{{ parentProject.uri }}" target="_blank">{{ parentProject.title }}</a> (used
          under <a href="{{ licenseUrl }}" target="_blank">CC BY-SA</a>).</ng-container
        >
        <ng-container *ngSwitchDefault i18n
          ><a href="{{ project.uri }}" target="_blank">This unit</a> is a copy of
          <a href="{{ parentProject.uri }}" target="_blank">{{ parentProject.title }}</a> by
          {{ parentAuthorsString }} (used under
          <a href="{{ licenseUrl }}" target="_blank">CC BY-SA</a>).</ng-container
        >
      </span>
      /
      <span
        *ngIf="!project.license"
        class="more-info"
        [matTooltip]="licenseInfo"
        matTooltipPosition="above"
        tabindex="0"
        i18n
        >More</span
      >
      <a *ngIf="project.license" href="{{ project.license }}" target="_blank" i18n>View License</a>
    </div>
  </div>
</div>
<div
  mat-dialog-actions
  fxLayout.gt-sm="row"
  fxLayout="column"
  fxLayoutAlign="end"
  fxLayoutGap="8px"
  fxLayoutGap.gt-sm="16px"
>
  <button mat-button cdkFocusInitial (click)="onClose()" i18n>Close</button>
  <button
    *ngIf="isTeacher && !isRunProject && project.wiseVersion !== 4"
    mat-flat-button
    color="accent"
    (click)="runProject()"
  >
    <mat-icon>supervised_user_circle</mat-icon>&nbsp;<ng-container i18n
      >Use with Class</ng-container
    >
  </button>
  <button mat-flat-button color="primary" (click)="previewProject()">
    <mat-icon>preview</mat-icon>&nbsp;<ng-container i18n>Preview</ng-container>
  </button>
</div>
