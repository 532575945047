<span class="tag" [ngStyle]="{ 'background-color': color, color: textColor }">
  <span>{{ text }}</span>
  @if (allowRemove) {
    <span class="divider"></span>
    <button
      mat-icon-button
      class="remove-tag"
      (click)="removeTagEvent.emit()"
      aria-label="Remove tag"
      i18n-aria-label
    >
      <mat-icon class="remove-icon">cancel</mat-icon>
    </button>
  }
</span>
