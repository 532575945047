<h2 mat-dialog-title i18n>Manage Tags</h2>
<mat-dialog-content class="dialog-content-scroll">
  <div fxLayout="column" fxLayoutGap="12px">
    @if (showCreateTag) {
      <edit-tag (closeEvent)="showCreateTag = false" />
    } @else {
      <div>
        <button mat-flat-button color="primary" (click)="showCreateTag = !showCreateTag" i18n>
          + New Tag
        </button>
      </div>
    }
    <mat-list class="app-bg-bg">
      @for (tag of tags; track tag.id; let tagIndex = $index; let last = $last) {
        <div>
          <mat-list-item>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              @if (idToEditing[tag.id]) {
                <div class="edit-tag">
                  <edit-tag [tag]="tag" (closeEvent)="cancelEditing(tag)" />
                </div>
              } @else {
                <tag class="mat-small" [color]="tag.color" [text]="tag.text" />
                <span fxFlex></span>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="idToEditing[tag.id] = !idToEditing[tag.id]; showCreateTag = false"
                  matTooltip="Edit"
                  matTooltipPosition="above"
                  i18n-matTooltip
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="delete(tag)"
                  matTooltip="Delete"
                  matTooltipPosition="above"
                  i18n-matTooltip
                >
                  <mat-icon>close</mat-icon>
                </button>
              }
            </div>
          </mat-list-item>
          @if (!last) {
            <mat-divider />
          }
        </div>
      }
    </mat-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button cdkFocusInitial mat-dialog-close i18n>Close</button>
</mat-dialog-actions>
