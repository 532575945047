<button
  mat-icon-button
  *ngIf="showArchive"
  (click)="archiveProjectsEvent.emit(true)"
  matTooltip="Archive"
  matTooltipPosition="above"
  i18n-matTooltip
>
  <mat-icon>archive</mat-icon>
</button>
<button
  mat-icon-button
  *ngIf="!showArchive"
  (click)="archiveProjectsEvent.emit(false)"
  matTooltip="Restore"
  matTooltipPosition="above"
  i18n-matTooltip
>
  <mat-icon>unarchive</mat-icon>
</button>
